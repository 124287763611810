<div class="menu-title" *ngIf="isDesktop">
  <a routerLink="/"
    >{{ title1 }} <br />
    {{ title2 }}</a
  >
</div>
<div class="ipad-menu-title" *ngIf="isIpad">
  <a routerLink="/"
    >{{ title1 }} <br />
    {{ title2 }}</a
  >
</div>
<div class="mobile-menu-title" *ngIf="isMobile">
  <a routerLink="/"
    >{{ title1 }}
    {{ title2 }}</a
  >
</div>
