<style>

.footer{
bottom: 0%;
width: 100%;
z-index: -2;
text-align: center;
position: relative;
height: auto;
padding-top: 40px;
padding-bottom: 40px;
clear: both;
font-family: 'Quicksand', sans-serif;
font-weight: 400;
font-size: 1vh;
}
</style>



<div><footer class="footer"> &copy; 2021 Kathryn E Lovejoy kathryn@suki-art.com</footer>
</div>
