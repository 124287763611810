import { Component, OnInit } from '@angular/core';
import {ResponsiveService} from './core/services/responsive.service'
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Kathryn E Lovejoy';

  constructor(private responsiveService:ResponsiveService, private router: Router,){
  }

  ngOnInit(){


    this.responsiveService.getMobileStatus().subscribe( isMobile =>{
      if(isMobile){
        console.log('Mobile device detected')
      }
    });
    this.onResize();  
    this.responsiveService.getIpadStatus().subscribe( isIpad =>{
      if(isIpad){
        console.log('Ipad device detected')
      }
    });
    this.responsiveService.getDesktopStatus().subscribe( isDesktop =>{
      if(isDesktop){
        console.log('Desktop detected')
      }
    });
    this.onResize();     

  
  }

  
  onResize(){
    this.responsiveService.checkWidth();
  }
  scrollHandler(e) {
    console.log(e)
    // should log top or bottom
  }
 
}


