import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { TitleComponent } from './components/title/title.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    LayoutComponent,
    TitleComponent,
    FooterComponent
  
  ],
  imports: [CommonModule, RouterModule],
  exports: [LayoutComponent]
})
export class LayoutModule {}
