// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDvqwfhO3E-2jiTJGWjI5FN5DT4CSoBat4",
    authDomain: "kathrynport-9d6e7.firebaseapp.com",
    databaseURL: "https://kathrynport-9d6e7.firebaseio.com",
    projectId: "kathrynport-9d6e7",
    storageBucket: "kathrynport-9d6e7.appspot.com",
    messagingSenderId: "21644300151",
    appId: "1:21644300151:web:f83f407dd524bb8976db72",
    measurementId: "G-HPRFL816MY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
