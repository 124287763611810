import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent  {

  title1 =`Kathryn`;
  title2 = `E Lovejoy`;
  
  public isMobile: Boolean;
  public isIpad: Boolean;
  public isDesktop: boolean;

  constructor(private responsiveService: ResponsiveService) { }
  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
   
  }
  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.responsiveService.getIpadStatus().subscribe(isIpad => {
      this.isIpad = isIpad;
    });
    this.responsiveService.getDesktopStatus().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
  }
  
}
