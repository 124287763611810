import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';


const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      { path: '', loadChildren: () => import('./Pages/aboutpage/aboutpage.module').then(m => m.AboutpageModule)},
      { path: 'contact', loadChildren: () => import('./Pages/contactpage/contactpage.module').then(m => m.ContactpageModule)},
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
