// globals.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ResponsiveService {
    private isMobile = new Subject();
    private isIpad = new Subject();
    private isDesktop = new Subject();
    public screenWidth: string;


    constructor() {
        this.checkWidth();
    }

    onMobileChange(status: boolean) {
        this.isMobile.next(status);
    }

    onIpadChange(status: boolean) {
      this.isIpad.next(status);
    }
    onDesktopChange(status: boolean) {
    this.isDesktop.next(status);
    } 

    getMobileStatus(): Observable<any> {
        return this.isMobile.asObservable();
    }

    getIpadStatus(): Observable<any> {
      return this.isIpad.asObservable();
    }
    getDesktopStatus(): Observable<any> {
      return this.isDesktop.asObservable();
    }


    public checkWidth() {
        var width = window.innerWidth;
        if (width <= 700) {
            this.screenWidth = 'sm';
            this.onMobileChange(true);
            this.onIpadChange(false);
            this.onDesktopChange(false);
        } else if (width > 700 && width <= 1024) {
            this.screenWidth = 'md';
            this.onIpadChange(true);
            this.onMobileChange(false);
            this.onDesktopChange(false);
        } else {
            this.screenWidth = 'lg';
            this.onMobileChange(false);
            this.onIpadChange(false);
            this.onDesktopChange(true);
        }
    }

}