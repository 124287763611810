import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LayoutModule } from './core/layout/layout.module';
import {ContactService} from './core/services/contact.service';
import { ResponsiveService } from './core/services/responsive.service';
import { StoreModule } from '@ngrx/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LazyImaageDirective } from './lazyimage.directive copy';


@NgModule({
  declarations: [AppComponent, LazyImaageDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, {}),
    
    
  ],
  providers: [ContactService, ResponsiveService],
  bootstrap: [AppComponent],
})
export class AppModule {}
